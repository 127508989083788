// react
import PropTypes from 'prop-types';

// mui
import { Dialog, Typography, DialogContent, Button, DialogActions, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const ConfirmationDialog = ({
  open,
  closeDialogHandler,
  dialogTitle,
  dialogContentText,
  dialogContentComponent,
  confirmationButtonText,
  closeButtonText,
  confirmationHandler,
  loading,
  confirmationButtonVariant,
  maxWidth,
  customButtons
}) => (
  <Dialog open={open} fullWidth maxWidth={maxWidth || 'sm'} onClose={closeDialogHandler}>
    <DialogTitle variant="h3">{dialogTitle}</DialogTitle>
    {dialogContentText && (
      <DialogContent>
        <Typography>{dialogContentText}</Typography>
      </DialogContent>
    )}
    {dialogContentComponent && (
      <DialogContent>
        <Typography>{dialogContentComponent}</Typography>
      </DialogContent>
    )}
    <DialogActions>
      {!customButtons && (
        <>
          <Button variant="outlined" onClick={() => closeDialogHandler()} disabled={loading ?? false}>
            {closeButtonText || 'Cancel'}
          </Button>
          <LoadingButton
            color={confirmationButtonVariant || 'primary'}
            loading={loading ?? false}
            variant="contained"
            onClick={confirmationHandler}
          >
            <span>{confirmationButtonText}</span>
          </LoadingButton>
        </>
      )}
      {customButtons}
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialogHandler: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogContentText: PropTypes.string,
  dialogContentComponent: PropTypes.node,
  confirmationButtonText: PropTypes.string.isRequired,
  confirmationButtonVariant: PropTypes.string,
  confirmationHandler: PropTypes.func.isRequired,
  closeButtonText: PropTypes.string,
  loading: PropTypes.bool,
  maxWidth: PropTypes.string,
  customButtons: PropTypes.node
};

export default ConfirmationDialog;
